import React from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

interface ComponentProps {
  title?: string | JSX.Element
  height?: number | string
  width?: number | string
  radius?: number
  overflow?: number | string
  children?: React.ReactNode
  color?: string
  p?: number
}

export default function Component({
  title,
  height,
  width,
  radius,
  overflow,
  children,
  color,
  p = 2,
}: ComponentProps): JSX.Element {
  return (
    <Box
      sx={{
        backgroundColor: color || '#fefefe',
        height: height || 'inherit',
        width: width || 'inherit',
        border: '1px solid #ddd',
        borderRadius: radius ?? 3,
        overflow: overflow || 'hidden',
      }}
    >
      {title && (
        <Grid container justifyContent="center">
          <Typography
            variant="h6"
            sx={{
              padding: '16px',
              paddingBottom: '0px',
            }}
          >
            {title}
          </Typography>
        </Grid>
      )}
      <Box p={p}>{children}</Box>
    </Box>
  )
}

export function ComponentDense({
  title,
  height,
  width,
  radius,
  overflow,
  children,
  p = 0,
}: ComponentProps): JSX.Element {
  return (
    <Box
      sx={{
        backgroundColor: '#fefefe',
        height: height || 'inherit',
        width: width || 'inherit',
        border: '1px solid #ddd',
        borderRadius: radius ?? 3,
        overflow: overflow || 'hidden',
      }}
    >
      {title && (
        <Grid container justifyContent="center">
          <Typography
            variant="h6"
            sx={{
              padding: '16px',
              paddingBottom: '0px',
            }}
          >
            {title}
          </Typography>
        </Grid>
      )}
      <Box p={p}>{children}</Box>
    </Box>
  )
}
