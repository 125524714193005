import pencilSVG from './pencil_small.svg'

// create a pencil HTMLImageElement we can use on our canvas without actually rendering anything right now
const pencilImg = new Image(512, 512)
pencilImg.src = pencilSVG

const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

const getRandomDecimal = (min: number, max: number) => {
  return Math.random() * (max - min) + min
}

const getRandomSignChanger = () => {
  const num = getRandomNumber(0, 1)
  if (num === 0) {
    return -1
  } else {
    return 1
  }
}

export default class Pencil {
  private MIN_VEL = 0.2
  private MAX_VEL = 0.3
  private MAX_ROT = 0.004
  private MIN_ROT = -0.004
  private STEP_SIZE = 1
  private BASE_MIN_SIZE = 0.02
  private BASE_MAX_SIZE = 0.07
  private MED_MIN_SIZE = 0.09
  private MED_MAX_SIZE = 0.12
  private BIG_MIN_SIZE = 0.17
  private BIG_MAX_SIZE = 0.2

  xpos: number
  ypos: number
  renderedXpos: number // position after accounting for rotation
  renderedYpos: number
  xDirection: number // going left/right
  yDirection: number // going up/down
  xspeed: number
  yspeed: number
  dx: number // delta x aka the change in xpos per animation frame
  dy: number
  scale: number
  degree: number // current degree of the pencil in its rotation
  dDegree: number // change in pencil degree / spin speed

  constructor() {
    this.xpos = getRandomNumber(0, window.innerWidth)
    this.ypos = getRandomNumber(0, window.innerHeight)
    this.degree = Math.PI * getRandomDecimal(0, 2)
    this.renderedXpos = 0
    this.renderedYpos = 0
    this.xspeed = getRandomDecimal(this.MIN_VEL, this.MAX_VEL)
    this.yspeed = getRandomDecimal(this.MIN_VEL, this.MAX_VEL)
    this.xDirection = getRandomSignChanger()
    this.yDirection = getRandomSignChanger()
    this.dDegree = getRandomDecimal(this.MIN_ROT, this.MAX_ROT)
    this.dx = this.STEP_SIZE * this.xspeed
    this.dy = this.STEP_SIZE * this.yspeed

    // assign random pencil sizes but weighted for mostly smaller to medium sized pencils
    this.scale = getRandomDecimal(this.MED_MIN_SIZE, this.BIG_MAX_SIZE) // small by default, 61% chance to stay small
    // const sizeDeterminer = getRandomNumber(1, 100)
    // if (sizeDeterminer > 60) {
    //   // 61 to 94 gives a medium pencil, 34% chance
    //   this.scale = getRandomDecimal(this.MED_MIN_SIZE, this.MED_MAX_SIZE)
    // } else if (sizeDeterminer > 94) {
    //   // 95 gives a large pencil, 5% chance
    //   this.scale = getRandomDecimal(this.BIG_MIN_SIZE, this.BIG_MAX_SIZE)
    // }
  }

  draw(context: CanvasRenderingContext2D): void {
    if (context) {
      const screenWidthModifyer =
        window.innerWidth < 700 ? 700 / window.innerWidth : 1
      // set context origin and scale to match center of image being drawn
      context.setTransform(
        this.scale * screenWidthModifyer,
        0,
        0,
        this.scale * screenWidthModifyer,
        this.renderedXpos,
        this.renderedYpos,
      )
      context.rotate(this.degree)

      // set opacity to scale
      context.globalAlpha = this.scale / this.BIG_MAX_SIZE
      context.drawImage(pencilImg, -pencilImg.width / 2, -pencilImg.height / 2)
      context.globalAlpha = 1 // reset
    }
  }

  update(context: CanvasRenderingContext2D): void {
    // page bounds accounting for image size
    const pageHeighthM: number =
        pencilImg.height * this.scale * 2 + window.innerHeight,
      pageWidthM: number = pencilImg.width * this.scale * 2 + window.innerWidth

    // update positions and degree of rotation
    this.xpos += this.dx * this.xDirection
    this.ypos += this.dy * this.yDirection
    this.degree += this.dDegree

    // set rendered pos to current pos accounted for page and image sizes
    this.renderedXpos =
      (((this.xpos % pageWidthM) + pageWidthM) % pageWidthM) -
      pencilImg.width * this.scale
    this.renderedYpos =
      (((this.ypos % pageHeighthM) + pageHeighthM) % pageHeighthM) -
      pencilImg.height * this.scale

    this.draw(context)
  }
}
