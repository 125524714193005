import * as React from 'react'
import AppContext, { appStateReducer, defaultAppState } from './AppContext'
import { useReducer } from 'react'
import AppRouter from './AppRouter'
import { ThemeProvider, createTheme } from '@mui/material'

const theme = createTheme({
  typography: {
    fontFamily: ['Fredoka'].join(','),
  },
})

function App(): JSX.Element {
  const [appState, appReducer] = useReducer(appStateReducer, defaultAppState)

  return (
    <>
      <ThemeProvider theme={theme}>
        <AppContext.Provider value={{ appState, appReducer }}>
          <AppRouter></AppRouter>
        </AppContext.Provider>
      </ThemeProvider>
    </>
  )
}

export default App
