import instance from '.'
import { err, getResult } from '../utils/functions'
import { APIResult } from '../utils/types'

export function createTopic(
  topic: string,
  quantity?: number,
  method?: string,
): Promise<APIResult<string>> {
  return instance
    .get('/api/openai/topic', {
      params: {
        topic,
        quantity,
        method,
      },
    })
    .catch((e) => err(e))
    .then((res) => getResult<string>(res))
}
