import { useEffect } from 'react'
import Pencil from './PencilClass'
import React from 'react'

const BackgroundAnimation = (): JSX.Element => {
  const NUM_PENCILS = 20
  useEffect(() => {
    // pull the 'data' of the canvas on our page so we can manipulate it
    const canvas = document.getElementById('canvas') as HTMLCanvasElement
    // create an array of all pencils
    const allPencils: Pencil[] = []

    if (canvas) {
      // set bounds of canvas to page size
      canvas.width = window.innerWidth
      canvas.height = window.innerHeight
      const context = canvas.getContext('2d')

      // create pencils
      for (let i = 1; i <= NUM_PENCILS; i++) {
        const pencilTemplate = new Pencil()
        allPencils.push(pencilTemplate)
      }

      // sort array so smallest pencils are drawn first and dont overlap larger pencils (which should look closer to the camera)
      allPencils.sort((a, b) => {
        return a.scale - b.scale
      })

      if (context) {
        // create an animation function
        const updatePencils = () => {
          // set up an animation that repeats this function
          requestAnimationFrame(updatePencils)

          // erase canvas and set back to default scale/position
          context.setTransform(1, 0, 0, 1, 0, 0)
          context.rotate(0)

          // update position and redraw them
          allPencils.forEach((pencil: Pencil): void => {
            pencil.update(context)
          })

          context.setTransform(1, 0, 0, 1, 0, 0)
          context.fillStyle = 'rgba(241, 242, 244, 0.8)'
          context.fillRect(0, 0, canvas.width, canvas.height)
        }
        // add a listener for resizes that will account for new page height/width values
        window.onresize = () => {
          canvas.height = window.innerHeight
          canvas.width = window.innerWidth
        }
        // call the animation function
        updatePencils()
      }
    }
  }, [])

  return (
    <canvas
      id="canvas"
      style={{
        top: 0,
        right: 0,
        position: 'fixed',
        zIndex: '-100',
      }}
    ></canvas>
  )
}

export default BackgroundAnimation
